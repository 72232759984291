import { z } from 'zod';

import { Carrier } from './getBusinessSubscriptions.schema';

export const SimType = {
  ESIM_NO_PIN: 'ESIM_NO_PIN',
  ESIM_PIN: 'ESIM_PIN',
  REGULAR_NO_PIN: 'REGULAR_NO_PIN',
  REGULAR_PIN: 'REGULAR_PIN',
  IOT_NO_PIN: 'IOT_NO_PIN',
  IOT_PIN: 'IOT_PIN',
  // Test SIMs
  TEST_ESIM_NO_PIN: 'TEST_ESIM_NO_PIN',
  TEST_ESIM_PIN: 'TEST_ESIM_PIN',
  TEST_REGULAR_NO_PIN: 'TEST_REGULAR_NO_PIN',
  TEST_REGULAR_PIN: 'TEST_REGULAR_PIN',
  TEST_IOT_NO_PIN: 'TEST_IOT_NO_PIN',
  TEST_IOT_PIN: 'TEST_IOT_PIN',
} as const;

const simCredentialsSchema = z
  .object({
    pin: z.string(),
    pin2: z.string(),
    puk: z.string(),
    puk2: z.string(),
  })
  .nullable();

export type SimType = (typeof SimType)[keyof typeof SimType];

export const simSchema = z.object({
  simId: z.string(),
  simType: z.nativeEnum(SimType),
  createdAt: z.string(),
  carrier: z.lazy(() => z.nativeEnum(Carrier)),
  simStatus: z.enum(['AVAILABLE', 'IN_USE', 'AGING', 'NOT_ASSIGNABLE', 'RESERVED']).optional(),
  credentials: simCredentialsSchema,
});

export const additionalSimSchema = z.object({
  assignedPhoneNumber: z.string().nullable(),
  isTwinSim: z.boolean(),
  sim: simSchema,
  createdAt: z.string(),
  terminatedAt: z.string().nullable(),
  subscription: z.string(),
});

export type SimDto = z.infer<typeof simSchema>;
export type AdditionalSimDto = z.infer<typeof additionalSimSchema>;
