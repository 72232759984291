import {
  Navigate,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { OrderRoutes } from '@/features/order/routes/OrderRoutes';
import { AppProvider } from '@/providers/app';
import { lazyImport } from '@/utils/lazyImport';
import { routes } from './config';
import { AdminRoute, ProtectedApp } from './protected';
import { PublicApp } from './public';

const { SubscriptionsRoutes } = lazyImport(
  () => import('@/features/subscriptions/routes'),
  'SubscriptionsRoutes',
);

const { RoamingCostPage } = lazyImport(
  () => import('@/features/call-rates/routes/RoamingCostPage'),
  'RoamingCostPage',
);

const { ProductCategoriesOverview } = lazyImport(
  () => import('@/features/products/routes/ProductCategoriesOverview'),
  'ProductCategoriesOverview',
);

const { DomesticNumberingPlans } = lazyImport(
  () => import('@/features/call-rates/routes/DomesticNumberPlans'),
  'DomesticNumberingPlans',
);

const { Landing } = lazyImport(() => import('@/features/landing/routes/Landing'), 'Landing');
const { DataCategory } = lazyImport(
  () => import('@/features/landing/routes/DataCategory'),
  'DataCategory',
);
const { VoiceCategory } = lazyImport(
  () => import('@/features/landing/routes/VoiceCategory'),
  'VoiceCategory',
);
const { IotCategory } = lazyImport(
  () => import('@/features/landing/routes/IotCategory'),
  'IotCategory',
);

// const { Recommendations } = lazyImport(
//   () => import('@/features/recommendations'),
//   'Recommendations',
// );
const { Coverage } = lazyImport(() => import('@/features/coverage'), 'Coverage');

const { UserManagement } = lazyImport(
  () => import('@/features/user-management/routes/UserManagement'),
  'UserManagement',
);

const { Terms } = lazyImport(() => import('@/features/terms-of-service/routes/Terms'), 'Terms');
const { ApnNetworkConfigs } = lazyImport(
  () => import('@/features/apn-network-configs/routes/ApnNetworkConfigs'),
  'ApnNetworkConfigs',
);
const { Orders } = lazyImport(() => import('@/features/orders/routes/Orders'), 'Orders');

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <AppProvider>
          <Outlet />
        </AppProvider>
      }
    >
      <Route element={<PublicApp />}>
        <Route index element={<Landing />} />
        <Route path={routes.landing.category.voice.path} element={<VoiceCategory />} />
        <Route path={routes.landing.category.data.path} element={<DataCategory />} />
        <Route path={routes.landing.category.iot.path} element={<IotCategory />} />
      </Route>

      <Route element={<ProtectedApp />}>
        <Route
          path={routes.overview.path}
          element={<Navigate to={routes.subscriptions.user.path} replace />}
        />
        <Route path='/products' element={<ProductCategoriesOverview />} />
        <Route path='/subscriptions/*' element={<SubscriptionsRoutes />} />
        {/* <Route path={routes.recommendations.path} element={<Recommendations />} /> */}
        <Route path='/order/*' element={<OrderRoutes />} />
        <Route path={routes.orders.path} element={<Orders />} />
        <Route path={routes.activate.path} element={<Orders />} />
        <Route path={routes.terms.path} element={<Terms />} />
        <Route path={routes.iot.path} element={<AdminRoute />}>
          <Route index element={<ApnNetworkConfigs />} />
        </Route>
        <Route path={routes.admin.userManagement.path} element={<AdminRoute />}>
          <Route index element={<UserManagement />} />
        </Route>
        <Route
          path={routes.callRates.domesticNumberPlans.path}
          element={<DomesticNumberingPlans />}
        />
        <Route path={routes.callRates.roam.path} element={<RoamingCostPage />} />
        <Route path={routes.coverage.path} element={<Coverage />} />
      </Route>
    </Route>,
  ),
);
