import '@/styles/global.scss';
import '@/styles/tailwind.scss';
import '@total-typescript/ts-reset';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { router } from '@/routes';
import './fontawesome';

// eslint-disable-next-line no-restricted-properties
if (import.meta.env.DEV) {
  const { worker } = await import('@/test/msw/browser/browser');

  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

/* // Set default theme to dark if no theme preference is set
if (!localStorage.getItem('bfTheme')) {
  localStorage.setItem('bfTheme', '"dark"');
} */

const rootElement = document.getElementById('root')!;

const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

import('./utils/sentry').then(({ default: initializeSentry }) => initializeSentry());
